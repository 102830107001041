import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "F:/web/active/infoamazonia-mercurio/src/components/reports/report-layout.js";
import Image from "!url-loader!../../images/ouro.jpg";
export const meta = {
  name: "gold",
  title: "Rastreando el oro contaminado",
  intro: "Una investigación de las redes de tráfico de mercurio en el Amazonas revela cómo el oro venezolano se blanquea en las cadenas de suministro legítimas y podría terminar en productos fabricados por las mayores corporaciones del mundo.",
  by: "Por Bram Ebus (texto y fotografía). Videos por Tom Laffay",
  image: Image
};
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Video = makeShortcode("Video");
const Gallery = makeShortcode("Gallery");
const Sidenote = makeShortcode("Sidenote");
const layoutProps = {
  meta,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p><strong parentName="p">{`La informalidad y la corrupción`}</strong>{` prevalecen en la región del Escudo Guayanés, donde la minería ilegal de oro lubrica los motores de sus economías de selva. En Guyana -antigua Guayana Británica, al este de Venezuela- se observa a agentes de policía con collares y pulseras de oro aún más grandes que las que llevan los propios mineros y, en los minimercados de algunos pueblos, se etiqueta el precio de los productos básicos en gramos de oro.`}</p>
    <p>{`El negocio es tan poderoso que alimenta una multimillonaria industria clandestina de mercurio. Una investigación sobre la dinámica del comercio formal e informal de mercurio en la región del Escudo Guayanés permitió comprobar a la vez que el oro extraído en Guyana, Surinam e, incluso, Venezuela, circula a lo largo de algunas de las mismas rutas de tráfico. Redes complejas mueven sus riquezas a través de las selvas interiores, por fronteras no supervisadas y los principales puertos del Océano Atlántico Norte.`}</p>
    <p>{`El noreste del Amazonas se ha convertido en uno de los principales puntos de tránsito del oro venezolano ilegal, según aducen múltiples comerciantes de oro, traficantes y fuentes policiales. Aseguran que se trafica con él en toda la región y se le borran sus orígenes para terminar abriéndose camino a los mercados internacionales y hasta algunas de las mayores empresas multinacionales del mundo.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMCBAX/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAc1qmZtUmH//xAAbEAADAAIDAAAAAAAAAAAAAAAAAhEBAxITIv/aAAgBAQABBQJJPMzgRoNt5HZD/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGRABAAMBAQAAAAAAAAAAAAAAABEhMQEC/9oACAEBAAY/Ar9O2pjEQ//EABsQAQACAgMAAAAAAAAAAAAAAAEAESExQWHR/9oACAEBAAE/IU3ZfFMEBYxUyZKQdO+4w9QppH//2gAMAwEAAgADAAAAEOTv/8QAFhEBAQEAAAAAAAAAAAAAAAAAABFh/9oACAEDAQE/EE1//8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERIf/aAAgBAgEBPxBqmn//xAAdEAEAAgIDAQEAAAAAAAAAAAABESEAMUFhoZHh/9oACAEBAAE/EAwoGCWzRlp5BMsN+4y1ZyS4lFA7S00eYxEnP09d4gUDV/mf/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/c5599e2a970ec1f0de283d5381422f53/e93cc/Guyana-35.webp 300w", "/static/c5599e2a970ec1f0de283d5381422f53/b0544/Guyana-35.webp 600w", "/static/c5599e2a970ec1f0de283d5381422f53/68fc1/Guyana-35.webp 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/c5599e2a970ec1f0de283d5381422f53/73b64/Guyana-35.jpg 300w", "/static/c5599e2a970ec1f0de283d5381422f53/3ad8d/Guyana-35.jpg 600w", "/static/c5599e2a970ec1f0de283d5381422f53/6c738/Guyana-35.jpg 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/c5599e2a970ec1f0de283d5381422f53/6c738/Guyana-35.jpg",
            "alt": "Las tiendas de conveniencia de algunos pueblos etiquetan el precio de los productos básicos en gramos de oro",
            "title": "Las tiendas de conveniencia de algunos pueblos etiquetan el precio de los productos básicos en gramos de oro",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`Las tiendas de conveniencia de algunos pueblos etiquetan el precio de los productos básicos en gramos de oro`}</em></p>
    <blockquote>
      <p parentName="blockquote">{`El oro suele extraerse ilegalmente en Guyana y Surinam, pero no comparte el secreto y la violencia que envuelve a la minería en Venezuela`}</p>
    </blockquote>
    <p>{`El oro suele extraerse de manera ilegal en Guyana y Surinam, pero esos lugares no comparten el secreto y la violencia que envuelven a la minería en Venezuela, donde las minas ilegales están controladas por oficiales del ejército corruptos, sindicatos del crimen organizado y grupos rebeldes colombianos. La industria del oro en Venezuela está plagada de abusos de los derechos humanos y violaciones del medio ambiente y, desde la creación del Arco Minero del Orinoco en 2016 -un área de 112.000 kilómetros cuadrados en Venezuela destinada al desarrollo minero-, el oro venezolano se ha enfrentado a una creciente presión internacional.`}</p>
    <p><strong parentName="p">{`El Arco Minero del Orinoco`}</strong></p>
    <iframe height="480" src="https://infoamazonia.org/pt/embed/?map_only=1&map_id=17448&width=960&height=480"></iframe>
    <p>{`>`}{`>`}{` `}<strong parentName="p">{`En marzo de 2019`}</strong>{`, la Oficina de Control de Activos Extranjeros (Ofac, por sus iniciales en inglés) del Departamento del Tesoro de Estados Unidos sancionó a la empresa minera estatal venezolana Minerven y a su presidente, argumentando que el sector aurífero ofrece al “régimen de Maduro” una línea de salvamento para mantenerse en el poder. “Perseguiremos agresivamente a los involucrados en el insensato comercio ilícito de oro de Maduro que contribuye con esta crisis financiera, humanitaria y ambiental”, aseguró el Secretario del Tesoro estadounidense, Steven Mnuchin, en `}<a parentName="p" {...{
        "href": "https://home.treasury.gov/news/press-releases/sm631"
      }}>{`una declaración`}</a>{`.`}</p>
    <p>{`>`}{`>`}{` `}<strong parentName="p">{`En julio de 2020`}</strong>{`, el Parlamento Europeo pidió la prohibición inmediata del comercio y la circulación del oro venezolano. “El llamado oro de sangre se extrae y explota en circunstancias ilegales y criminales en detrimento de los derechos humanos y el medio ambiente”, `}<a parentName="p" {...{
        "href": "https://www.europarl.europa.eu/doceo/document/TA-9-2020-0193_EN.html"
      }}>{`indica la resolución`}</a>{`.`}</p>
    <p>{`>`}{`>`}{` `}<strong parentName="p">{`Adicionalmente, en julio de 2020`}</strong>{`, la Oficina de Derechos Humanos de las Naciones Unidas publicó `}<a parentName="p" {...{
        "href": "https://www.ohchr.org/EN/HRBodies/HRC/RegularSessions/Session44/Documents/A_HRC_44_54.pdf"
      }}>{`un informe`}</a>{` que condena las actividades criminales y los graves abusos de los derechos humanos en las regiones mineras de Venezuela. “Las autoridades deben tomar medidas inmediatas para poner fin a la explotación laboral y sexual, al trabajo infantil y a la trata de personas, y deben desmantelar los grupos delictivos que controlan las actividades mineras. Asimismo, deben investigar, enjuiciar y castigar a los responsables de violaciones, abusos y crímenes en contra de los derechos humanos”, `}<a parentName="p" {...{
        "href": "https://news.un.org/en/story/2020/07/1068391"
      }}>{`declaró`}</a>{` Michelle Bachelet, Alta Comisionada de las Naciones Unidas para los Derechos Humanos.`}</p>
    <p>{`Venezuela exporta parte del oro a las refinerías de sus aliados políticos y comerciales en países como Turquía, Irán y los Emiratos Árabes Unidos. Los compradores en Europa y Norteamérica intentan evitar el sucio oro venezolano para proteger su imagen pública y evitar una repercusión política.`}</p>
    <blockquote>
      <p parentName="blockquote">{`El oro sucio sale de sus instalaciones en barras brillantes estampadas con sus logotipos y se vende a clientes internacionales`}</p>
    </blockquote>
    <p>{`En 2019, el gobierno de Donald Trump llegó a prohibir a las empresas estadounidenses la compra de oro venezolano. Pero, ya sea con consciencia de ello o sin conocimiento, el oro venezolano sigue alcanzándolas después de que es lavado en Guyana, Surinam y otros países productores y de tránsito de oro en América Latina, y vendido a las refinerías de oro internacionales. El oro sucio sale de sus instalaciones en barras brillantes estampadas con sus logotipos y se vende a clientes internacionales, que incorporan el metal precioso a nuestros teléfonos, ordenadores, carros y otras tecnologías.`}</p>
    <p>{`Las prohibiciones y sanciones internacionales han empujado con más fuerza el comercio del oro venezolano a las manos de actores criminales, lo que ha aumentado los ingresos ilícitos y dificultado el rastreo del origen, según afirman expertos y venezolanos involucrados en el comercio de oro. “El gobierno de Estados Unidos tiene que preparar una estrategia, porque cuanto más cierren la puerta, mayor será el margen de beneficios para `}{`[el gobierno de Maduro]`}{`”, dice uno de los traficantes.`}</p>
    <hr></hr>
    <p>{`Una ruta anteriormente desconocida, por la que se trafica con oro venezolano, corre a través de Guyana. La antigua colonia británica, vecino de Venezuela al este, es tanto un país de tránsito como de destino, donde traficantes y comerciantes internacionales canalizan el oro ilegal con documentos falsos hacia la cadena de suministro regular.`}</p>
    <p>{`El oro entra a Guyana por tierra, río y aire. Pequeñas cantidades son trasladadas por refugiados venezolanos que llegan de las regiones mineras del estado Bolívar y traen consigo algunos gramos de oro en lugar de ahorros en efectivo.`}</p>
    <p>{`Una mujer venezolana explica que antes de salir de la terminal de ferris y chalanas de San Félix, en el estado Bolívar, a orillas del río Orinoco, al sur de Venezuela, la Guardia Nacional venezolana humilla y obliga a hombres y mujeres a desnudarse, agacharse y saltar en cuclillas, en búsqueda de bolsas escondidas con unos gramos de oro. Muchas mujeres venezolanas, a menudo refugiadas y madres solteras, terminan siendo explotadas sexualmente en las regiones mineras de Guyana, donde se les paga con oro.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "64.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAQC/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAWN3kipJ/8QAGRABAQEBAQEAAAAAAAAAAAAAAgEDBBIU/9oACAEBAAEFAvp9URy8+njPhwDJMNxAWf8A/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAHRAAAQMFAQAAAAAAAAAAAAAAAAECERITITFxgv/aAAgBAQAGPwJqpyB9O4xnZFDl4XHbkT0hliH/xAAaEAEAAwEBAQAAAAAAAAAAAAABABEhQTHw/9oACAEBAAE/ITGNLd3cs4jUAncXtMm5EYORAPhhxSilp7P/2gAMAwEAAgADAAAAEGc//8QAFhEBAQEAAAAAAAAAAAAAAAAAAAEx/9oACAEDAQE/ENR//8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARIf/aAAgBAgEBPxBc5Lf/xAAdEAEAAgICAwAAAAAAAAAAAAABABEhMUFRYYGR/9oACAEBAAE/EGJkHjlYTioUUSKO+TwVfuIUl3st1AWPdvAMve4AeyjoEnyojGgFcts//9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/6c623ac12011f74ecba59b4180ac3fc5/e93cc/Guyana-51.webp 300w", "/static/6c623ac12011f74ecba59b4180ac3fc5/b0544/Guyana-51.webp 600w", "/static/6c623ac12011f74ecba59b4180ac3fc5/68fc1/Guyana-51.webp 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/6c623ac12011f74ecba59b4180ac3fc5/73b64/Guyana-51.jpg 300w", "/static/6c623ac12011f74ecba59b4180ac3fc5/3ad8d/Guyana-51.jpg 600w", "/static/6c623ac12011f74ecba59b4180ac3fc5/6c738/Guyana-51.jpg 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/6c623ac12011f74ecba59b4180ac3fc5/6c738/Guyana-51.jpg",
            "alt": "Muchas mujeres venezolanas, a menudo refugiadas y madres solteras, terminan siendo explotadas sexualmente en las regiones mineras de Guyana.",
            "title": "Muchas mujeres venezolanas, a menudo refugiadas y madres solteras, terminan siendo explotadas sexualmente en las regiones mineras de Guyana.",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`Muchas mujeres venezolanas, a menudo refugiadas y madres solteras, terminan siendo explotadas sexualmente en las regiones mineras de Guyana.`}</em></p>
    <p>{`Pero las cantidades que traen consigo los refugiados y migrantes no compiten con los grandes actores del mercado, quienes mueven cientos de kilogramos de oro sucio por vez.`}</p>
    <p>{`Un comerciante de oro guyanés, que accedió a una entrevista con la condición de permanecer en el anonimato, admite haber comprado oro venezolano y también afirma que lo ha traído a Guyana por pistas de aterrizaje clandestinas en la selva. Según el traficante, aviones pequeños vuelan dos veces al mes de Venezuela a las zonas fronterizas de Guyana, transportando unos 200 kilos de oro en cada ocasión. “Nos encontramos con aviones y pistas de aterrizaje ilegales en Guyana”, dice Sam Sittlington, ex asesor de la Unidad Especial contra el Crimen Organizado de Guyana (Socu, por sus siglas en inglés).`}</p>
    <Video url="https://youtube.com/embed/667YSC2CFRc" mdxType="Video" />
    <p>{`Desde los inicios de la crisis económica y humanitaria en Venezuela en 2013, la hiperinflación en el país se ha disparado. Los comerciantes venezolanos que no disponen de efectivo ofrecen el oro a precios de descuento, a veces 40% por debajo de la tasa del mercado, con el fin de obtener divisas. “No hay  que hacer ningún papeleo con ese oro”, dice el comerciante de oro guyanés. “Están hambrientos de dólares, y por eso pueden permitirse dar 200 kilos a crédito”.`}</p>
    <p>{`Los traficantes también llevan oro venezolano a Guyana a través de Brasil. El traficante guyanés afirma que, hasta hace poco, en Guyana se traficaban alrededor de diez kilos por semana a través de la frontera con Brasil por Lethem, en lo profundo de la selva en el alto río Esequibo, que luego enviaban en vuelos directos a Georgetown, la capital del país sobre la costa norte.`}</p>
    <p>{`El dinero en efectivo se lleva a través de la frontera a Venezuela o a Brasil mediante transportistas ilegales, según afirman varias fuentes, incluyendo un oficial de inteligencia extranjero y un comerciante de oro de Guyana. Ambos solicitaron el anonimato para hablar libremente. Los transportistas ilegales de efectivo que cruzan de frontera a frontera llevan millones de dólares estadounidenses, libras esterlinas o euros, a la vez. “Incluso si la aduana incautara el dinero, lo devolvería a los transportistas ilegales en un par de días”, dice Sittlington.`}</p>
    <blockquote>
      <p parentName="blockquote">{`No hay ningún trámite con el oro”, dice el comerciante de oro guyanés`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAID/9oADAMBAAIQAxAAAAGCtap01CcL/8QAGhAAAgMBAQAAAAAAAAAAAAAAAQIAAxESIf/aAAgBAQABBQLIGNcDPnnFuuUcZ//EABcRAQADAAAAAAAAAAAAAAAAAAABERL/2gAIAQMBAT8BpmH/xAAVEQEBAAAAAAAAAAAAAAAAAAAAIf/aAAgBAgEBPwFX/8QAHRAAAgEEAwAAAAAAAAAAAAAAAAERAhAhMUFhof/aAAgBAQAGPwJPhm4kw/ClRo6t/8QAGhAAAgMBAQAAAAAAAAAAAAAAAREAITFBYf/aAAgBAQABPyErYsptj2vYQCkIswfuZYQZNern/9oADAMBAAIAAwAAABAUP//EABgRAAIDAAAAAAAAAAAAAAAAAAAhARFh/9oACAEDAQE/EEdGEH//xAAYEQACAwAAAAAAAAAAAAAAAAAAEQExUf/aAAgBAgEBPxBObHo//8QAGxABAQEAAwEBAAAAAAAAAAAAAREAIUFRMYH/2gAIAQEAAT8QqS3+vPmTgvCmo8wgMoATcziFQFZkeSaO73rEU+N//9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/95957ecebc70fe9cbd1343795481a182/e93cc/IMG_3791.webp 300w", "/static/95957ecebc70fe9cbd1343795481a182/b0544/IMG_3791.webp 600w", "/static/95957ecebc70fe9cbd1343795481a182/68fc1/IMG_3791.webp 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/95957ecebc70fe9cbd1343795481a182/73b64/IMG_3791.jpg 300w", "/static/95957ecebc70fe9cbd1343795481a182/3ad8d/IMG_3791.jpg 600w", "/static/95957ecebc70fe9cbd1343795481a182/6c738/IMG_3791.jpg 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/95957ecebc70fe9cbd1343795481a182/6c738/IMG_3791.jpg",
            "alt": "Centenas de kilos por semana son traficados en Guyana a través de la ciudad fronteriza de Lethem.",
            "title": "Centenas de kilos por semana son traficados en Guyana a través de la ciudad fronteriza de Lethem.",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`Centenas de kilos por semana son traficados en Guyana a través de la ciudad fronteriza de Lethem.`}</em></p>
    <p>{`Los transportistas ilegales, a menudo jóvenes estudiantes de Boa Vista -ciudad del estado brasileño de Roraima-, traen dinero en efectivo a Brasil hasta tres veces por semana, según el oficial de inteligencia extranjero. “Utilizan muchachos muy jóvenes y les pagan 50 dólares por traerlo”, asegura.`}</p>
    <p>{`Se desconoce la cantidad total de oro venezolano que circula por Guyana, pero es evidente que una parte termina en manos de algunos de los principales exportadores de oro de Guyana, de acuerdo a fuentes gubernamentales de alto rango, que hablaron para este reportaje con la condición de permanecer en el anonimato. Un especialista en minería, familiarizado con la llegada del oro venezolano a Guyana desde 2018, cree que todos los exportadores de oro de Guyana están involucrados. “No es difícil transportarlo de Venezuela a Georgetown”, dice.`}</p>
    <blockquote>
      <p parentName="blockquote">{`“Hemos recibido informes de que tenemos comerciantes que compran oro proveniente de Venezuela”`}</p>
    </blockquote>
    <p>{`La Junta de Oro de Guyana, que supervisa el refinado y la exportación de oro en el país, es consciente de que el oro venezolano puede estar filtrándose en la cadena de suministros del país pero, hasta ahora, no ha podido ponerle freno. “Hemos recibido informes de que tenemos comerciantes que compran oro proveniente de Venezuela”, dice Eondrene Thompson, gerente general de la Junta de Oro de Guyana (GGB, por sus siglas en inglés). Advirtió que, sin embargo, la junta no tiene pruebas físicas que demuestren que el oro venezolano se trafica en Guyana.`}</p>
    <Gallery mdxType="Gallery">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFAv/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAFudpxIRYF//8QAGRAAAwEBAQAAAAAAAAAAAAAAAQIDEgAR/9oACAEBAAEFApbC2Ka85CZtnYM17//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/AYf/xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPwGn/8QAHBAAAQQDAQAAAAAAAAAAAAAAAAERITEQEiKR/9oACAEBAAY/ArcWJLNHOlX3H//EABoQAQEBAQADAAAAAAAAAAAAAAERADEhQVH/2gAIAQEAAT8hAbJ4fMKjxp9slDfPdJqY5Xx3/9oADAMBAAIAAwAAABBvz//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/ECP/xAAXEQEAAwAAAAAAAAAAAAAAAAAAAREx/9oACAECAQE/EMKh/8QAHhABAAICAQUAAAAAAAAAAAAAAQARIUFhMVFxkdH/2gAIAQEAAT8QEbIK5rvzDNj63YjfJGdGNLAAdMmu2oKzJQ2D2QGa8qfJ/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/9348f0e45f96007f7e21b32be866daff/e93cc/Guyana-10.webp 300w", "/static/9348f0e45f96007f7e21b32be866daff/b0544/Guyana-10.webp 600w", "/static/9348f0e45f96007f7e21b32be866daff/68fc1/Guyana-10.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/9348f0e45f96007f7e21b32be866daff/73b64/Guyana-10.jpg 300w", "/static/9348f0e45f96007f7e21b32be866daff/3ad8d/Guyana-10.jpg 600w", "/static/9348f0e45f96007f7e21b32be866daff/6c738/Guyana-10.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/9348f0e45f96007f7e21b32be866daff/6c738/Guyana-10.jpg",
              "alt": "It's not difficult to transport from Venezuela to Georgetown",
              "title": "It's not difficult to transport from Venezuela to Georgetown",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`It's not difficult to transport from Venezuela to Georgetown`}</em></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAwAEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAC/9oADAMBAAIQAxAAAAHjoqwzTR//xAAZEAADAAMAAAAAAAAAAAAAAAAAARECIjH/2gAIAQEAAQUCFUQx1fXT/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8BiP/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABgQAAMBAQAAAAAAAAAAAAAAAAAQESFx/9oACAEBAAY/AjqkNX//xAAbEAEAAgMBAQAAAAAAAAAAAAABABEhMVFhgf/aAAgBAQABPyEFdX5KHAajxmUpD7MIi2WFCp//2gAMAwEAAgADAAAAEEQf/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEB/9oACAEDAQE/EMpD/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAECAQE/EFy1v//EABsQAQEBAQADAQAAAAAAAAAAAAERADEhQWFx/9oACAEBAAE/EAAL4YWR6Ev5kWmH2hgNUnRemTqA83LggZzf/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/d6b72f3dde8fdd4e266700b7317fc982/e93cc/Guyana-27.webp 300w", "/static/d6b72f3dde8fdd4e266700b7317fc982/b0544/Guyana-27.webp 600w", "/static/d6b72f3dde8fdd4e266700b7317fc982/68fc1/Guyana-27.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/d6b72f3dde8fdd4e266700b7317fc982/73b64/Guyana-27.jpg 300w", "/static/d6b72f3dde8fdd4e266700b7317fc982/3ad8d/Guyana-27.jpg 600w", "/static/d6b72f3dde8fdd4e266700b7317fc982/6c738/Guyana-27.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/d6b72f3dde8fdd4e266700b7317fc982/6c738/Guyana-27.jpg",
              "alt": "Guyana 27",
              "title": "Guyana 27",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
    </Gallery>
    <p>{`Sin embargo, de acuerdo con Newell Dennison, comisionado de la Comisión de Geología y Minas de Guyana (GGMC, por sus siglas en inglés), las cantidades de oro extraído en Guyana difieren de la cantidad efectivamente declarada y comercializada. “La cantidad de oro que se declara a la GGB no se concilia fácilmente con la cantidad de oro que nuestros funcionarios de la GGMC determinan que se produce a partir de las operaciones in situ", dice. “Nos hemos encontrado con situaciones en las que la diferencia es alarmante”.`}</p>
    <p>{`La producción de oro en las minas de menores dimensiones de Guyana se contrajo en el 2019, según múltiples fuentes, debido a problemas de acceso al combustible y a la reducción de las inversiones públicas en el sector. No obstante, las declaraciones de oro de los comerciantes y exportadores aumentaron en el 2019, lo que podría representar el oro procedente de minas ilegales de Guyana, o el oro traficado a través de Guyana desde otros países.`}</p>
    <p>{`En los primeros cinco meses de 2020, los comerciantes de oro de Guyana declararon 940 kilos más que en el mismo período del 2019 -un aumento de casi 27% en medio de  la pandemia de la Covid-19-. La GGB también ha descubierto que los mineros registran las direcciones de los hoteles del interior de Guyana como el lugar de origen de donde supuestamente obtuvieron su oro, en vez de la dirección real de las minas.`}</p>
    <Sidenote side="left" mdxType="Sidenote">
	Comerciantes de oro de Guyana declararon 940 kg más que en el mismo período del 2019 ―un aumento de casi el 27% durante la pandemia de la COVID-19
    </Sidenote>
    <iframe height="480" src="https://datawrapper.dwcdn.net/Dg0r7/2/"></iframe>
    <p>{`Fuentes del sector, incluidos comerciantes de oro, traficantes y organismos policiales, alegan que por lo menos dos de los exportadores de oro autorizados de Guyana, El Dorado Trading y Mohamed’s Trading, han comprado oro venezolano ilícito. El Dorado Trading exporta oro a Guardian International Gold Corp. en Canadá, Tony Goetz en Bélgica y Argor-Heraeus en Suiza. Mohamed’s Trading exporta a Zee Gold DMCC en los Emiratos Árabes Unidos.`}</p>
    <p>{`Otras compañías, incluyendo varias multinacionales reconocidas, compran el oro directamente de estas refinerías. Argor-Heraeus, por ejemplo, vende su oro a Apple, Samsung, Microsoft, Phillip Morris, Ford y Tesla. Entre los clientes de Tony Goetz se encuentran Sony, Starbucks y Hewlett-Packard.`}</p>
    <p>{`Fuentes familiarizadas con las prácticas de las empresas afirman que los mineros guyaneses que trabajan en Venezuela llevan el oro a Guyana y lo venden a El Dorado Trading y, en ocasiones, ambas empresas exportadoras compran oro directamente a delincuentes venezolanos, conocidos como malandros, en la frontera.`}</p>
    <p>{`El Dorado Trading negó las acusaciones de que la empresa haya comprado oro venezolano, y Guardian International, Tony Goetz y Argor-Heraeus afirmaron que no tenían motivos para creer que El Dorado les hubiera vendido oro venezolano. "Nuestras propias normas y las normas legales en Suiza están entre las más estrictas del mundo y estamos absolutamente decididos a seguirlas", escribió el jefe de comunicaciones de Argos-Heraeus en un correo electrónico.`}</p>
    <p>{`Mohamed's Trading no respondió a las solicitudes de comentarios.`}</p>
    <p>{`Un traficante de oro venezolano alega que Mohamed’s Trading, propiedad de Nazar Mohamed, está comprando oro a líderes militares del régimen venezolano. “Mohamed, ese es quien manda allí `}{`[en Guyana]`}{`”, dice. “Él es quien está lanzando dólares a diestra y siniestra a esta gente”.`}</p>
    <blockquote>
      <p parentName="blockquote">{`“Desde allí, `}{`[el oro]`}{` sale ilegalmente hacia Guyana, lo entregan y traen dólares”`}</p>
    </blockquote>
    <p>{`El traficante afirma que los militares le compran el oro a la guerrilla colombiana del Ejército de Liberación Nacional (ELN) cerca de una mina llamada Corre Gente, en el estado Bolívar, en Venezuela. “Allí hay una pista de aterrizaje”, afirma. “Desde allí, `}{`[el oro]`}{` sale ilegalmente hacia Guyana, lo entregan y traen dólares”.`}</p>
    <p>{`Una fuente de un servicio de inteligencia extranjero afirma que Mohamed es el “principal protagonista” involucrado en el tráfico de bienes ilícitos de Venezuela a Guyana y luego al extranjero. “`}{`[Mohamed]`}{` está comprando oro venezolano; definitivamente es el mayor comprador, no hay duda. También está traficando cocaína y armas de fuego desde Venezuela”, explica el oficial de inteligencia extranjero, y añade que las armas se venden a clientes en Surinam y la cocaína sale a los mercados internacionales.`}</p>
    <p>{`El traficante de oro también afirma que los líderes de Mohamed y El Dorado Trading tienen conocimiento de la relación de las empresas con el oro venezolano. “Están plenamente conscientes, porque tienen que dar el dinero por adelantado para la compra de moneda estadounidense en dólares de Guyana para pagar el oro y, a veces, ellos mismos mandan su valor por avión a través de la selva, hacia el interior”. La fuente afirmó haber vendido en una ocasión oro venezolano a El Dorado Trading.`}</p>
    <Video url="https://youtube.com/embed/lpQ-zLmw6pE" mdxType="Video" />
    <p>{`Los dirigentes de ambas empresas parecen estar conectados con las élites políticas de Guyana; ambos habrían aportado fondos tanto al actual partido gobernante como al anterior, y Mohamed habría donado un vehículo blindado al nuevo presidente, Irfaan Ali.`}</p>
    <p>{`Tamesh Jagmohan, director de El Dorado Trading, sugirió que las acusaciones contra la empresa "emanan de personas con motivos ocultos": “No es raro en Guyana que ciertas agrupaciones privadas o públicas combinen esfuerzos para atacar a otras con fines competitivos, financieros o políticos", completa.`}</p>
    <p>{`Independientemente de las acusaciones de contrabando de oro contra El Dorado Trading, el propio Jagmohan ha sido investigado por el la Oficina Federal de Investigaciones (FBI, por sus siglas en inglés) por contrabando de oro a Estados Unidos, y se sospecha que él y su compañía han lavado dinero para la organización terrorista libanesa Hezbollah. Jagmohan fue detenido en el aeropuerto de Miami en 2012 con 27 lingotes de oro que no declaró. Según una persona con conocimiento de la investigación, se le impuso una multa de 40.000 dólares y se le revocó la visa.`}</p>
    <p>{`Según Sittlington, la Socu también ha descubierto que personas muy adornadas con joyas pasan oro de contrabando en vuelos a mercados de América del Norte, como Miami, y que las bolsas que contienen oro traspasan la seguridad del aeropuerto: "En otras palabras, hay corrupción en el aeropuerto", añade Sittlington. Según una fuente del gobierno, a finales de 2019, las autoridades detuvieron un envío de El Dorado de unos 125 kilogramos de oro listo para despegar hacia Barbados. El cargamento despertó sospechas después de que los funcionarios identificaron irregularidades con el sello y notaron que estaba previsto que saliera de un aeropuerto que no se utiliza para transportes oficiales de oro.`}</p>
    <p>{`Parte del oro pasa por Surinam y luego se lleva a los Países Bajos, a veces en vuelos comerciales de KLM, antes de ser trasladado a Bélgica. KLM no pudo confirmar ni negar que había transportado oro en el pasado debido a la "naturaleza sensible de los envíos", pero añadió que en julio de 2019 decidió dejar de transportar oro venezolano.`}</p>
    <p>{`Además de traficar con oro venezolano, los principales compradores de oro obtienen grandes beneficios utilizando para el dólar estadounidense un tipo de cambio diferente al de los bancos y el Estado, ganando unos pocos dólares por cada gramo de oro. También se gana más dinero a través de la evasión de impuestos. Los mineros venden su oro con un cargo por impuestos de 8,5 por ciento, que El Dorado Trading y Mohamed's Trading deben pagar al Estado. A menudo esto no sucede, y los impuestos pagados por los pequeños mineros se los quedan los mayores exportadores de oro de Guyana.`}</p>
    <p>{`A pesar de que los funcionarios tienen conocimiento del tráfico y la evasión fiscal, no han podido ponerle fin. El oficial de inteligencia extranjera en Guyana se queja de que no se pueden construir casos legales debido a la profunda corrupción de las fuerzas del orden de Guyana. "La fuerza policial es parte del problema", dice, "no parte de la solución".`}</p>
    <hr></hr>
    <p>{`No todo el oro extraído o traficado en Guyana se vende directamente desde allí a refinerías internacionales. La anarquía y las fronteras sin supervisión del noreste de la cuenca amazónica permiten que gran parte de ese oro tenga todavía otra escala en lugares donde los impuestos son más bajos y donde se paga con divisas, como, por ejemplo, el vecino Surinam.`}</p>
    <Video url="https://youtube.com/embed/veX0nCa5ARQ" mdxType="Video" />
    <p>{`Sobre la ribera occidental del río Courantyne, que sirve de frontera entre Guyana y Surinam, se encuentra un pueblo pequeño llamado Corriverton. Desde allí, ferris transportan con frecuencia pasajeros y mercancía a través del río hasta Surinam, ofreciendo amplias oportunidades a los traficantes. Showtime (un seudónimo), comerciante ilegal de la localidad con trayectoria en la minería y el tráfico de oro, explica en pocas palabras cómo funcionan las economías de contrabando. “Todo lo que sé hacer es ir al interior, trabajar y llevar a casa el oro para mi familia”, dice Showtime. "Ahora me doy cuenta de que, si llevo ese oro a Surinam, voy a recibir el doble de dinero para mantener a mi familia. Así que voy a tratar de ser lo suficientemente creativo para llevar mi oro hasta allá”.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"si llevo ese oro a Surinam, voy a recibir el doble de dinero para mantener a mi familia"`}</p>
    </blockquote>
    <p>{`No es el único. “Se cree que, calculando por lo bajo, de 35% a 45% de nuestra producción de oro sale del país por contrabando, principalmente hacia Surinam”, dice Gabriel Lall, ex presidente de la GGB. “Y a eso le sumamos el supuesto oro venezolano que aparece `}{`[en Guyana]`}{`”.`}</p>
    <p>{`Hasta hace poco, Surinam era el país más atractivo de la región para vender el metal precioso, con bajas tasas de regalías y ausencia de impuestos sobre el oro traído por los mineros de todo el Escudo Guayanés. Además, las tiendas de oro de Surinam solían pagar en dólares estadounidenses o euros, mientras que la mayoría de las tiendas de oro de Guyana, especialmente las más pequeñas, solo ofrecen la moneda local, el dólar guyanés.`}</p>
    <p>{`A medida que el precio del metal se disparaba en los dos últimos años, las tiendas de oro brotaron como hongos por toda Paramaribo, la capital de Surinam, antigua Guayana Neerlandesa. Algunas tiendas de oro más pequeñas e independientes en el centro de la ciudad procesan uno o dos kilos al día y compran principalmente pequeñas cantidades a los mineros del interior. Sin embargo, hay participantes más grandes en el mercado. Uno de los mayores compradores de oro con licencia de exportación afirma que compra hasta 50 kilos por día a las tiendas de oro de toda la capital, un valor de mercado de más de tres millones de dólares.`}</p>
    <Gallery mdxType="Gallery">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGQAAAQUAAAAAAAAAAAAAAAAAAAECAwQF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAABylrTAND/xAAbEAABBAMAAAAAAAAAAAAAAAACAAEDERASIv/aAAgBAQABBQLaxaJzHtsBKQK1/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGhAAAgIDAAAAAAAAAAAAAAAAAREAEAJBUf/aAAgBAQAGPwIPXYconSBr/8QAGxAAAwACAwAAAAAAAAAAAAAAAAERMUEhUaH/2gAIAQEAAT8haIRcArEcejSfjsyJ9rwo/9oADAMBAAIAAwAAABCT7//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPxBkf//EAB0QAQADAAEFAAAAAAAAAAAAAAEAETEhQVFhcaH/2gAIAQEAAT8QQzO7NHavsSoyOhfUpkQVVo8RKbGvECC7F3Kquq8s/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/8a9bacc3b3e21b7894c3dd14d14d489b/e93cc/DSC07015.webp 300w", "/static/8a9bacc3b3e21b7894c3dd14d14d489b/b0544/DSC07015.webp 600w", "/static/8a9bacc3b3e21b7894c3dd14d14d489b/68fc1/DSC07015.webp 1200w", "/static/8a9bacc3b3e21b7894c3dd14d14d489b/0b978/DSC07015.webp 1400w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/8a9bacc3b3e21b7894c3dd14d14d489b/73b64/DSC07015.jpg 300w", "/static/8a9bacc3b3e21b7894c3dd14d14d489b/3ad8d/DSC07015.jpg 600w", "/static/8a9bacc3b3e21b7894c3dd14d14d489b/6c738/DSC07015.jpg 1200w", "/static/8a9bacc3b3e21b7894c3dd14d14d489b/22cab/DSC07015.jpg 1400w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/8a9bacc3b3e21b7894c3dd14d14d489b/6c738/DSC07015.jpg",
              "alt": "A medida que el precio del oro se disparaba en los dos últimos años, las tiendas de oro aparecieron como hongos por toda Paramaribo..",
              "title": "A medida que el precio del oro se disparaba en los dos últimos años, las tiendas de oro aparecieron como hongos por toda Paramaribo..",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`A medida que el precio del oro se disparaba en los dos últimos años, las tiendas de oro aparecieron como hongos por toda Paramaribo.`}</em></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAECBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHJcESIP//EABkQAAIDAQAAAAAAAAAAAAAAAAERAAIQMf/aAAgBAQABBQIWLv3EIp//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAYEAACAwAAAAAAAAAAAAAAAAAAMREgMv/aAAgBAQAGPwJm5oz/xAAZEAADAQEBAAAAAAAAAAAAAAAAARFRMUH/2gAIAQEAAT8hWlytlEwV4VrjIU8g2o//2gAMAwEAAgADAAAAEDc//8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAEDAQE/EI1//8QAFREBAQAAAAAAAAAAAAAAAAAAECH/2gAIAQIBAT8Qp//EAB0QAQEAAgEFAAAAAAAAAAAAAAERAHFBITFhkbH/2gAIAQEAAT8QHlv17Y1zkVRlF/TjAUSbwNiCeTJKLRwSK9Z//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/b146ad0313d0c143ce3c60aec6fa796e/e93cc/DSC07035.webp 300w", "/static/b146ad0313d0c143ce3c60aec6fa796e/b0544/DSC07035.webp 600w", "/static/b146ad0313d0c143ce3c60aec6fa796e/68fc1/DSC07035.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/b146ad0313d0c143ce3c60aec6fa796e/73b64/DSC07035.jpg 300w", "/static/b146ad0313d0c143ce3c60aec6fa796e/3ad8d/DSC07035.jpg 600w", "/static/b146ad0313d0c143ce3c60aec6fa796e/6c738/DSC07035.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/b146ad0313d0c143ce3c60aec6fa796e/6c738/DSC07035.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAwAF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQID/9oADAMBAAIQAxAAAAHNUZzOaK//xAAZEAACAwEAAAAAAAAAAAAAAAAAAQISIQP/2gAIAQEAAQUC2TXKpYkR0aTP/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAEhMf/aAAgBAwEBPwGMU//EABYRAAMAAAAAAAAAAAAAAAAAAAIQMf/aAAgBAgEBPwEqv//EABoQAAICAwAAAAAAAAAAAAAAAAARAjEBECH/2gAIAQEABj8CVDlLfcFI/8QAGhABAAMAAwAAAAAAAAAAAAAAAQARITFBYf/aAAgBAQABPyG8IMxUiO+YKLGpTYL7EsPTJ//aAAwDAQACAAMAAAAQ9N//xAAXEQEBAQEAAAAAAAAAAAAAAAABABEh/9oACAEDAQE/ECHFs//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/EEs//8QAGxABAAIDAQEAAAAAAAAAAAAAAQARITFBYXH/2gAIAQEAAT8QEigXnKwDZJsQr1uBYAg4Q3EN6DZ2J6y+oeRgw6rs/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/1955505b6f7aa1de89f88179df623995/e93cc/DSC07454.webp 300w", "/static/1955505b6f7aa1de89f88179df623995/b0544/DSC07454.webp 600w", "/static/1955505b6f7aa1de89f88179df623995/68fc1/DSC07454.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/1955505b6f7aa1de89f88179df623995/73b64/DSC07454.jpg 300w", "/static/1955505b6f7aa1de89f88179df623995/3ad8d/DSC07454.jpg 600w", "/static/1955505b6f7aa1de89f88179df623995/6c738/DSC07454.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/1955505b6f7aa1de89f88179df623995/6c738/DSC07454.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAIBAwX/xAAUAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHCeXGkQT//xAAbEAACAgMBAAAAAAAAAAAAAAAAARITAhEhMf/aAAgBAQABBQLwrzjxEmbdZ//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/AYf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAaEAACAgMAAAAAAAAAAAAAAAAAEAEREiFR/9oACAEBAAY/AjKtHVK//8QAHBAAAwABBQAAAAAAAAAAAAAAAAERITFBUXGR/9oACAEBAAE/IX6EzJgfAMW3cE6rcND/2gAMAwEAAgADAAAAEOjP/8QAFhEAAwAAAAAAAAAAAAAAAAAAARAR/9oACAEDAQE/EDC//8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAECAQE/EIx//8QAGxABAQEAAwEBAAAAAAAAAAAAAREAMUFRYaH/2gAIAQEAAT8QpnJ+spPZN69maSVKtmHMlPCuJXcoMr9nOSpv/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/2f0e14519eea91dcb0a5756fbee93ae6/e93cc/DSC07458.webp 300w", "/static/2f0e14519eea91dcb0a5756fbee93ae6/b0544/DSC07458.webp 600w", "/static/2f0e14519eea91dcb0a5756fbee93ae6/68fc1/DSC07458.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/2f0e14519eea91dcb0a5756fbee93ae6/73b64/DSC07458.jpg 300w", "/static/2f0e14519eea91dcb0a5756fbee93ae6/3ad8d/DSC07458.jpg 600w", "/static/2f0e14519eea91dcb0a5756fbee93ae6/6c738/DSC07458.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/2f0e14519eea91dcb0a5756fbee93ae6/6c738/DSC07458.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHHeLRIUr//xAAZEAACAwEAAAAAAAAAAAAAAAAAAQIQEhH/2gAIAQEAAQUCitGUMi+Dr//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABcQAQADAAAAAAAAAAAAAAAAAAEAICH/2gAIAQEABj8CCY1//8QAGxABAAICAwAAAAAAAAAAAAAAAQARECExQVH/2gAIAQEAAT8hsAgPMamzXvcW461EouP/2gAMAwEAAgADAAAAENjP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQIBAT8QRn//xAAbEAEAAwEAAwAAAAAAAAAAAAABABEhMRBB0f/aAAgBAQABPxBAhLTN/E3gr4jjBoMAnPE7STE4168f/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/f17a7c0bffa837263e594323a3f7ccc5/e93cc/DSC07461.webp 300w", "/static/f17a7c0bffa837263e594323a3f7ccc5/b0544/DSC07461.webp 600w", "/static/f17a7c0bffa837263e594323a3f7ccc5/68fc1/DSC07461.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/f17a7c0bffa837263e594323a3f7ccc5/73b64/DSC07461.jpg 300w", "/static/f17a7c0bffa837263e594323a3f7ccc5/3ad8d/DSC07461.jpg 600w", "/static/f17a7c0bffa837263e594323a3f7ccc5/6c738/DSC07461.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/f17a7c0bffa837263e594323a3f7ccc5/6c738/DSC07461.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAQADBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAeMZoxH/xAAZEAADAQEBAAAAAAAAAAAAAAAAAQIhERL/2gAIAQEAAQUCmqZUtPBPh6Hp/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFxAAAwEAAAAAAAAAAAAAAAAAABEgIf/aAAgBAQAGPwJGz//EABkQAQEAAwEAAAAAAAAAAAAAAAEAETFBUf/aAAgBAQABPyEDDvkQNpDvMustcpdL/9oADAMBAAIAAwAAABDwD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABURAQEAAAAAAAAAAAAAAAAAABAh/9oACAECAQE/EKf/xAAZEAEBAQEBAQAAAAAAAAAAAAABEQAhMVH/2gAIAQEAAT8QRWRQB7uZMeWwyMo54XEaPzQYVare7ueKb//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/8cb766c29b50f66e3c9920965dc82c02/e93cc/DSC07475.webp 300w", "/static/8cb766c29b50f66e3c9920965dc82c02/b0544/DSC07475.webp 600w", "/static/8cb766c29b50f66e3c9920965dc82c02/68fc1/DSC07475.webp 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/webp"
            }}></source>{`
        `}<source parentName="picture" {...{
              "srcSet": ["/static/8cb766c29b50f66e3c9920965dc82c02/73b64/DSC07475.jpg 300w", "/static/8cb766c29b50f66e3c9920965dc82c02/3ad8d/DSC07475.jpg 600w", "/static/8cb766c29b50f66e3c9920965dc82c02/6c738/DSC07475.jpg 1200w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "type": "image/jpeg"
            }}></source>{`
        `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/8cb766c29b50f66e3c9920965dc82c02/6c738/DSC07475.jpg",
              "alt": "Alt",
              "title": "Alt",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
      `}</picture>{`
    `}</span></p>
    </Gallery>
    <p>{`Ourominas es una de las mayores cadenas de compra de oro en Surinam, con múltiples tiendas en Paramaribo. Los compradores como Ourominas no están obligados a pedir a los vendedores ningún tipo de certificación o documentos de origen. En una de sus tiendas en Paramaribo, un empleado declaró que la empresa no exige ningún tipo de documentación para comprar oro y, por lo tanto, es imposible saber si fue extraído en el país o en el extranjero.`}</p>
    <p>{`“Si es oro guyanés, tampoco lo sabemos”, dice el empleado. Por lo menos en otras diez tiendas de oro en Paramaribo ratificaron que no tenían ningún requisito ni proceso para averiguar de dónde proviene el oro, pero algunos empleados admitieron haber comprado con conocimiento oro de la vecina Guayana Francesa -al este-, de Guyana -al oeste-, e incluso de minas venezolanas.`}</p>
    <p>{`Uno de los quemadores de una importante tienda de oro, cuyo trabajo es quemar el mercurio de las amalgamas de oro y mercurio que provienen de las minas, comentó que un venezolano entra una o dos veces al mes para despachar hasta 30 kilos de oro, pero se negó a dar más información. Un traficante de oro venezolano consultado para este informe afirma que los traficantes lavan el oro extraído ilegalmente de Venezuela, incorporándolo a la cadena de suministros legal de Surinam.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMBBf/EABQBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAeDaYZtJ/8QAGBAAAgMAAAAAAAAAAAAAAAAAAQIAESD/2gAIAQEAAQUCqOqgY//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABcQAQEBAQAAAAAAAAAAAAAAAAECACD/2gAIAQEABj8CwzY8/wD/xAAaEAACAwEBAAAAAAAAAAAAAAABEQAQQSEx/9oACAEBAAE/IRD2LgHlPIRx1//aAAwDAQACAAMAAAAQAw//xAAWEQEBAQAAAAAAAAAAAAAAAAABEBH/2gAIAQMBAT8Q0Cf/xAAWEQEBAQAAAAAAAAAAAAAAAAABEBH/2gAIAQIBAT8QRUdn/8QAGhABAAIDAQAAAAAAAAAAAAAAAQARIVFxMf/aAAgBAQABPxBHLg3H8ftg9XLl7cK5BXpKJ//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/23fdc230a436324b2e0c7bc923ec548e/e93cc/DSC07464.webp 300w", "/static/23fdc230a436324b2e0c7bc923ec548e/b0544/DSC07464.webp 600w", "/static/23fdc230a436324b2e0c7bc923ec548e/68fc1/DSC07464.webp 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/23fdc230a436324b2e0c7bc923ec548e/73b64/DSC07464.jpg 300w", "/static/23fdc230a436324b2e0c7bc923ec548e/3ad8d/DSC07464.jpg 600w", "/static/23fdc230a436324b2e0c7bc923ec548e/6c738/DSC07464.jpg 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/23fdc230a436324b2e0c7bc923ec548e/6c738/DSC07464.jpg",
            "alt": "Una importante tienda de oro, cuyo trabajo es quemar el mercurio de las amalgamas de oro y mercurio que provienen de las minas",
            "title": "Una importante tienda de oro, cuyo trabajo es quemar el mercurio de las amalgamas de oro y mercurio que provienen de las minas",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span></p>
    <p>{`Algunas tiendas venden su oro al comerciante internacional de oro Kaloti Precious Metals, que tiene su refinería principal en Dubai, en Emiratos Árabes Unidos. Pero la multinacional también tiene una participación en la Casa de la Moneda de Kaloti Surinam (KMHS, por sus siglas en inglés).`}</p>
    <p>{`La KMHS, inaugurada en 2015, funde y analiza el oro, y luego calcula las regalías que deben pagarse al gobierno de Surinam. Gran parte del oro proveniente de las pequeñas y medianas minas del interior de Surinam tiene que pasar por la KMHS, una empresa conjunta entre la refinería de Kaloti en Dubai, los comerciantes locales y el Estado de Surinam. Kaloti Precious Metals posee una participación mayoritaria en la operación, mientras que los comerciantes de Surinam poseen 30% y el Estado apenas 10%.`}</p>
    <p>{`La KMHS nunca ha sido propietaria del metal precioso que entra por sus puertas, según su director, Ryan Tjon, pero sí monitorea a los compradores, y Tjon confirma que Kaloti Dubai es uno de los compradores de oro de Surinam. Kaloti consideró la construcción de esta industria en otros países de la región del Escudo Guayanés, pero finalmente eligió Surinam debido a su larga participación en la industria aurífera del país. La refinería de Kaloti en Dubai ha importado oro de Surinam desde la década de 1990.`}</p>
    <p>{`La Casa de la Moneda debía convertir a Surinam en un “competitivo centro de comercio de lingotes de oro y un centro de excelencia para la industria de metales preciosos", `}<a parentName="p" {...{
        "href": "http://www.kalotipm.com/Media-Center-PressReleases-Details/7/KALOTI-OPENS-SURINAMES-FIRST-GOLD-REFINERY"
      }}>{`de acuerdo con Munir Kaloti`}</a>{`, presidente de Kaloti Precious Metals. La KMHS tiene una capacidad de procesamiento de 60.000 kilos de oro al año, un volumen mucho mayor que la producción anual de oro de Surinam. Su objetivo fue desde el inicio procesar el oro de todos los países del Escudo Guayanés, pero el gobierno no promovió la importación de oro de sus vecinos y el proyecto aún no ha generado ganancias.`}</p>
    <p>{`En 2019, la KMHS procesó entre 15.000 y 20.000 kilos de oro, según Tjon. En teoría, esa es la mayoría del oro de Surinam que los doce exportadores del país compraron en operaciones de minería a pequeña escala o en las numerosas tiendas de oro del país. Pero, en la práctica, no hay garantías de que el oro que estos licenciatarios envían a la refinería antes de exportarlo realmente se extraiga en Surinam. “Sería muy difícil decir de dónde proviene”, declara Tjon.`}</p>
    <p>{`La KMHS está en posición de rechazar oro si se presentan irregularidades, como, por ejemplo, si se mueven cantidades inusuales de oro a través de la Casa de la Moneda. Sin embargo, esto no ha sucedido en los tres años que Tjon ha dirigido la compañía. El director de la Casa de la Moneda es consciente de que el tráfico de oro es un problema, “pero nosotros, la agencia, no somos los que investigamos de dónde viene realmente el oro”, dice: “No somos un oficial de policía”. De acuerdo con Tjon, la refinería prefiere confiar en que los exportadores actúen con la debida diligencia. Si un exportador declara que es oro de Surinam, la KMHS lo acepta de buena fe.`}</p>
    <blockquote>
      <p parentName="blockquote">{`“No somos un oficial de policía”. De acuerdo con Tjon, la refinería más bien confía en que los exportadores actúen con la debida diligencia.`}</p>
    </blockquote>
    <p>{`La falta de supervisión en todas las etapas, desde la tienda de oro más pequeña hasta la refinería con participación estatal, ha permitido a los traficantes lavar el oro, extraído ilegalmente, dentro de la cadena de suministros legal de Surinam. Pero una de las razones por las que es tan difícil para las autoridades tomar medidas drásticas contra el comercio ilícito es que, al igual que ocurre con el comercio de mercurio, las redes de tráfico de oro extraído ilegalmente están en continuo movimiento.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAUCAwT/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABq0IJQ5EIf//EABsQAAIBBQAAAAAAAAAAAAAAAAABAgQRExQx/9oACAEBAAEFAo1UTahbMhIfD//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/Aaf/xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPwGH/8QAFxAAAwEAAAAAAAAAAAAAAAAAARAxIP/aAAgBAQAGPwKKDH//xAAaEAEBAAIDAAAAAAAAAAAAAAABABEhMWGR/9oACAEBAAE/IQBcvEHuWdekDENCzf/aAAwDAQACAAMAAAAQRP8A/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAEDAQE/EIa//8QAFhEAAwAAAAAAAAAAAAAAAAAAAAER/9oACAECAQE/EHREf//EABsQAQADAAMBAAAAAAAAAAAAAAEAETEhUXFh/9oACAEBAAE/EGJHSXkrD7ieZcEnqaFsEGmrFDQoez//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/e290b5d854ae9416aa4900fa84abd977/e93cc/DSC07628.webp 300w", "/static/e290b5d854ae9416aa4900fa84abd977/b0544/DSC07628.webp 600w", "/static/e290b5d854ae9416aa4900fa84abd977/68fc1/DSC07628.webp 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/e290b5d854ae9416aa4900fa84abd977/73b64/DSC07628.jpg 300w", "/static/e290b5d854ae9416aa4900fa84abd977/3ad8d/DSC07628.jpg 600w", "/static/e290b5d854ae9416aa4900fa84abd977/6c738/DSC07628.jpg 1200w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/e290b5d854ae9416aa4900fa84abd977/6c738/DSC07628.jpg",
            "alt": "Paramaribo. La capital de Surinam está sufriendo la crisis económica.",
            "title": "Paramaribo. La capital de Surinam está sufriendo la crisis económica.",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span>{` `}<em parentName="p">{`Paramaribo. La capital de Surinam está sufriendo la crisis económica.`}</em></p>
    <p>{`La economía de Surinam se desplomó después de que el gobierno saliente de Desi Bouterse allanara en febrero el Banco Central y restringiera los tipos de cambio, lo que generó un pronunciado incremento de la inflación y llevó la economía al punto del colapso. Como consecuencia del desastre económico en curso, las monedas extranjeras comenzaron a ser escasas y el dólar de Surinam perdió rápidamente su valor.`}</p>
    <p>{`Algunas tiendas de oro de Paramaribo ahora sólo pagan en moneda surinamesa, según un minero de oro local, o  cargan 10% de comisión por los pagos en dólares estadounidenses. En Surinam se aplica un tipo de cambio más caro para el euro o el dólar estadounidense, lo que hace que los precios del oro sean ahora más bajos, hasta 20% menos que el precio previo de mercado.`}</p>
    <p>{`Otro comerciante afirma que esos cambios recientes han cambiado la dirección del comercio de oro de Surinam a través de la frontera. "El tráfico de oro se volvió atractivo de nuevo en Surinam", dice. "Pero ahora nuestro oro va a Guyana", dice otro comerciante, añadiendo que aquellos que son capaces de mover oro a través de la frontera hacia Guyana pueden ahorrar miles de dólares por kilogramo.`}</p>
    <p>{`El nuevo gobierno de Surinam, liderado por el presidente Chan Santokhi, ex comisario de policía, ha prometido reformar el sector de minería de oro del país, pero el nuevo vicepresidente del país, Ronnie Brunswijk, es un antiguo líder del grupo rebelde de la selva y narcotraficante, y uno de los principales actores de la industria del oro de Surinam, quien dirige varias minas en el interior del país. Si bien no es inusual que las élites políticas tengan una participación en el sector de la minería de oro de Surinam, queda por ver si el país puede acabar con el mercado negro mientras los que se benefician de él están en el poder.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      